<template>
  <v-app id="inspire" :style="{ backgroundColor }" v-cloak>
    <!-- PC 해더 -->
    <v-app-bar
      v-if="!$vuetify.breakpoint.mobile"
      app
      height="85"
      absolute
      elevation="0"
      color="white"
      class="header"
      v-intersect="{ handler: onScroll, options: { threshold: [0, 1.0] } }"
    >
      <!-- PC 네비게이션 -->
      <v-responsive width="100vw" height="100%" class="ma-0">
        <v-responsive
          width="100%"
          :max-width="maxWidth"
          height="100%"
          class="mx-auto"
        >
          <v-layout justify-center align-center class="fill-height">
            <!-- PC 전체메뉴 (primary) -->
            <v-flex shrink>
              <router-link to="/">
                <img src="/images/pinpay/ci.png" />
              </router-link>
            </v-flex>

            <v-spacer />

            <!-- PC 서브 메뉴들 -->
            <v-flex shrink>
              <v-layout slot="center" class="pl-4">
                <v-btn
                  width="150"
                  height="85"
                  dark
                  text
                  tile
                  x-large
                  color="black"
                  active-class="no-active"
                  :ripple="false"
                  to="/gshop/giftcards"
                  >모바일상품권</v-btn
                >

                <v-btn
                  width="150"
                  height="85"
                  dark
                  text
                  tile
                  x-large
                  color="black"
                  active-class="no-active"
                  :ripple="false"
                  to="/about"
                  >{{ siteInfo.serviceNameKo || siteInfo.projectNameKo }}24소개</v-btn
                >

                <v-btn
                  width="150"
                  height="85"
                  dark
                  text
                  tile
                  x-large
                  color="black"
                  active-class="no-active"
                  :ripple="false"
                  to="/center/faqs"
                  >자주묻는질문</v-btn
                >

                <v-btn
                  width="150"
                  height="85"
                  dark
                  text
                  tile
                  x-large
                  color="black"
                  active-class="no-active"
                  :ripple="false"
                  to="/center/notifications"
                  >공지사항</v-btn
                >

                <!-- <v-menu slot="prepend" open-on-hover offset-y>
                                    <v-btn to="/center/notifications" slot="activator" slot-scope="{ on }" v-on="on" active-class="no-active" width="120" height="60" :ripple="false" dark text tile x-large color="black">고객센터</v-btn>
                                    <v-list class="prepend-menu">
                                        <router-link to="/center/notifications" class="subtitle-2">공지사항</router-link>
                                        <router-link to="/center/faqs" class="subtitle-2">자주묻는질문</router-link>
                                    </v-list>
                                </v-menu> -->

                <v-btn
                  width="150"
                  height="85"
                  dark
                  text
                  tile
                  x-large
                  color="black"
                  active-class="no-active"
                  :ripple="false"
                  to="/board/question"
                  >후기 및 문의</v-btn
                >

                <!-- <v-menu slot="prepend" open-on-hover offset-y>
                                    <v-btn to="/gshop/giftcards" slot="activator" slot-scope="{ on }" v-on="on" active-class="no-active" width="120" height="60" :ripple="false" dark text tile x-large color="black">기프트샵</v-btn>
                                    <v-list class="prepend-menu">
                                        <router-link to="/gshop/giftcards?category-code=book" class="subtitle-2">도서문화상품권</router-link>
                                        <router-link to="/gshop/giftcards?category-code=cultureland" class="subtitle-2">컬쳐랜드</router-link>
                                        <router-link to="/gshop/giftcards?category-code=afreecatv" class="subtitle-2">아프리카TV 별풍선</router-link>
                                        <router-link to="/gshop/giftcards?category-code=google-giftcard" class="subtitle-2">구글 기프트카드</router-link>
                                        <router-link to="/gshop/giftcards?category-code=food" class="subtitle-2">외식·커피·케익 쿠폰</router-link>
                                        <router-link to="/gshop/giftcards?category-code=life" class="subtitle-2">주유·편의점·생활 쿠폰</router-link>
                                    </v-list>
                                </v-menu> -->
              </v-layout>
            </v-flex>

            <template v-if="siteInfo.memberPayable">
              <v-spacer />
              <v-flex shrink v-if="logon">
                <v-layout slot="center" class="pl-4">
                  <v-btn
                    height="85"
                    dark
                    text
                    tile
                    color="primary"
                    active-class="no-active"
                    :ripple="false"
                    @click="logout"
                    >로그아웃</v-btn
                  >
                  <v-btn
                    height="85"
                    dark
                    text
                    tile
                    color="primary"
                    active-class="no-active"
                    :ripple="false"
                    to="/member/gshop/orders"
                    >마이페이지</v-btn
                  >
                </v-layout>
              </v-flex>
              <v-flex shrink v-else>
                <v-layout slot="center" class="pl-4">
                  <v-btn
                    height="85"
                    dark
                    text
                    tile
                    color="primary"
                    active-class="no-active"
                    :ripple="false"
                    to="/login"
                    >로그인</v-btn
                  >
                  <v-btn
                    height="85"
                    dark
                    text
                    tile
                    color="primary"
                    active-class="no-active"
                    :ripple="false"
                    to="/join"
                    >회원가입</v-btn
                  >
                </v-layout>
              </v-flex>
            </template>
          </v-layout>
        </v-responsive>
      </v-responsive>
    </v-app-bar>

    <!-- Mobile 해더 -->
    <v-app-bar
      v-if="$vuetify.breakpoint.mobile"
      height="60"
      max-height="60"
      color="white"
      elevation="2"
      v-intersect="{ handler: onScroll, options: { threshold: [0, 1.0] } }"
    >
      <v-layout wrap>
        <v-responsive width="100%" height="60">
          <v-layout align-center fill-height>
            <!-- Mobile 햄버거 -->
            <v-app-bar-nav-icon @click="drawer = true" />

            <v-spacer />

            <!-- Mobile 로고 -->
            <router-link to="/" class="align-self-center">
              <img
                src="/images/pinpay/ci.png"
                width="100"
                height="30"
                style="display: block"
              />
            </router-link>

            <v-spacer />

            <v-sheet width="48"></v-sheet>
          </v-layout>
        </v-responsive>
      </v-layout>
    </v-app-bar>

    <!-- Mobile 네비게이션 -->
    <v-navigation-drawer
      v-model="drawer"
      app
      fixed
      temporary
      height="auto"
      width="100%"
    >
      <v-list max-height="100vh" class="overflow-auto pa-0">
        <!-- 로그인 / 로그아웃 -->
        <v-list-item class="primary" dark>
          <v-list-item-title>
            <span>{{ siteInfo.serviceNameKo || siteInfo.projectNameKo }}</span>
          </v-list-item-title>
          <v-list-item-action @click="drawer = false"
            ><v-icon>mdi-close</v-icon></v-list-item-action
          >
        </v-list-item>

        <!-- Mobile 서브메뉴 : 회사소개 -->
        <v-list-item class="white" active-class="no-active" to="/about">
          <v-list-item-title><b>회사소개</b></v-list-item-title>
        </v-list-item>

        <v-divider />

        <!-- Mobile 서브메뉴 : 문의하기 -->
        <v-list-item
          class="white"
          active-class="no-active"
          to="/board/question"
        >
          <v-list-item-title><b>문의하기</b></v-list-item-title>
        </v-list-item>

        <v-divider />

        <!-- Mobile 서브메뉴 : 도서 -->
        <v-list-group class="white">
          <v-list-item-title slot="activator"
            ><b>고객센터</b></v-list-item-title
          >
          <v-divider />
          <v-list-item-group>
            <v-list-item
              active-class="white secondary--text"
              to="/center/notifications"
            >
              <v-list-item-title>공지사항</v-list-item-title>
            </v-list-item>
            <v-list-item active-class="white secondary--text" to="/center/faqs">
              <v-list-item-title>자주묻는질문</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>

        <v-divider />

        <!-- Mobile 서브메뉴 : 상품권 -->
        <v-list-item
          class="white"
          active-class="no-active"
          to="/gshop/giftcards"
        >
          <v-list-item-title><b>기프트샵</b></v-list-item-title>
        </v-list-item>

        <v-divider />

        <template v-if="siteInfo.memberPayable && logon">
          <v-list-group class="white">
            <v-list-item-title slot="activator"
              ><b>마이페이지</b></v-list-item-title
            >
            <v-divider />
            <v-list-item-group>
              <v-list-item active-class="no-active" to="/member/profile">
                <v-list-item-title>회원정보수정</v-list-item-title>
              </v-list-item>
              <v-list-item active-class="no-active" to="/member/gshop/orders">
                <v-list-item-title>상품권 구매내역</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list-group>

          <v-divider />
        </template>

        <v-card elevation="0" class="pa-5">
          <v-card-subtitle class="pa-0">
            <span class="black--text">대표전화</span>
          </v-card-subtitle>
          <v-divider class="pa-0 my-2" />
          <v-card-title class="pa-0">
            <span class="mt-2 mr-1 subtitle-2 black--text">Tel.</span>
            <span class="headline primary--text">1544-7638</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <span class="caption black--text"
              >평일 10:00 - 17:00 / 점심 12:30 - 13:30</span
            >
          </v-card-text>
          <v-card-text class="pa-0">
            <span class="caption black--text">주말 및 공휴일 휴무</span>
          </v-card-text>
        </v-card>

        <template v-if="siteInfo.memberPayable">
          <v-divider />
          <v-list-item class="justify-end">
            <template v-if="logon">
              <v-list-item-action class="ma-0">
                <v-btn plain @click="logout"
                  ><v-icon left size="16">{{ mdiLogoutVariant }}</v-icon
                  >로그아웃</v-btn
                >
              </v-list-item-action>
            </template>
            <template v-else>
              <v-list-item-action class="ma-0">
                <v-btn plain active-class="no-active" to="/login"
                  ><v-icon left size="16">{{ mdiLockOutline }}</v-icon
                  >로그인</v-btn
                >
              </v-list-item-action>
              <v-list-item-action class="ma-0">
                <v-btn plain active-class="no-active" to="/join"
                  ><v-icon left size="16">{{ mdiAccountPlus }}</v-icon
                  >회원가입</v-btn
                >
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="pa-0 overflow-visible">
        <slot />
      </v-container>
    </v-main>

    <v-divider />

    <v-responsive
      min-height="200"
      v-intersect="{ handler: onFooter, options: { threshold: [0, 1.0] } }"
    >
      <v-footer
        color="#ffffff"
        class="body-2 overflow-auto footer-serve"
        height="64"
        :class="{ 'justify-center': !$vuetify.breakpoint.mobile }"
      >
        <v-responsive width="auto" :max-width="maxWidth">
          <v-row align="center" no-gutters class="py-2">
            <v-col cols="auto" class="pr-4">
              <router-link to="/terms/tos">이용약관</router-link>
            </v-col>
            <v-divider vertical class="my-1 grey lighten-2" />
            <v-col cols="auto" class="px-4">
              <router-link to="/terms/privacy">개인정보취급방침</router-link>
            </v-col>
          </v-row>
        </v-responsive>
      </v-footer>
      <hr
        data-v-3f0bee24=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />
      <v-footer color="white" min-height="73" class="justify-center">
        <v-responsive
          width="100vw"
          :max-width="maxWidth"
          class="py-6"
          :class="{ 'pa-4': $vuetify.breakpoint.mobile }"
        >
          <v-row>
            <v-col cols="12" sm="3" lg="2" class="mr-auto">
              <img
                style="display: block"
                src="/images/pinpay/footer-logo.png"
                width="200"
                height="60"
                contain
              />
            </v-col>
            <v-col cols="12" sm="9" lg="6">
              <custom-footer v-if="siteInfo?.customFooterEnabled" />
              <ul v-else class="footer-info">
                <li><b class="mr-2">회사명</b>제이컴퍼니</li>
                <li>
                  <b class="mr-2">주소</b>경기도 김포시 풍무로 131번길 24-37 2층
                </li>
                <li><b class="mr-2">대표번호</b>1544-7638</li>
                <li><b class="mr-2">대표자</b>전상욱</li>
                <li><b class="mr-2">대표핸드폰번호</b>010 6676 9132</li>
                <li><b class="mr-2">사업자등록번호</b>798-86-02661</li>
                <li><b class="mr-2">이메일</b>q66769132@gmail.com</li>
              </ul>
            </v-col>
            <!--
            <v-col lg="1" v-show="!$vuetify.breakpoint.mobile">
              <form name="KB_AUTHMARK_FORM" method="get">
                <input type="hidden" name="page" value="B009111" />
                <input type="hidden" name="cc" value="b010807:b008491" />
                <input
                  type="hidden"
                  name="mHValue"
                  value="b578d5cf6865db7e76580460d3359966"
                />
              </form>
              <a href="#" onclick="javascript:onPopKBAuthMark();return false;">
                <img
                  src="http://img1.kbstar.com/img/escrow/escrowcmark.gif"
                  border="0"
                />
              </a>
            </v-col>
            -->

            <v-col lg="3" v-show="!$vuetify.breakpoint.mobile">
              <img
                src="@/assets/brand.png"
                :width="$vuetify.breakpoint.mobile ? '230' : '294'"
                :height="$vuetify.breakpoint.mobile ? '80' : '94'"
                contain
              />
            </v-col>
          </v-row>
          <!-- <v-layout align-center>
                        <v-flex shrink>
                            <img style="display:block;" src="@/assets/logo.png" :width="$vuetify.breakpoint.mobile ? '104' : '104'" :height="$vuetify.breakpoint.mobile ? '50' : '50'" contain />
                        </v-flex>
                        <v-flex>
                            <v-row>
                                <v-col cols="12">
                                    <b>(주)플러스핀</b>
                                </v-col>
                                <v-col cols="12">
                                    <ul class="footer-info">
                                        <li><b class="mr-2">주소</b>광주광역시 북구 첨단과기로 208번길 43-10 IBC 지식산업센터 A동 1205호</li>
                                        <li><b class="mr-2">대표번호</b>1668-2445</li>
                                        <li><b class="mr-2">대표자</b>정재욱</li>
                                        <li><b class="mr-2">대표핸드폰번호</b>010-2531-2445</li>
                                        <li><b class="mr-2">회사명</b>주식회사 대한문고</li>
                                        <li><b class="mr-2">사업자등록번호</b>247-87-01822</li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-flex>
                    </v-layout> -->
        </v-responsive>
      </v-footer>
    </v-responsive>

    <!-- Floating Action Button -->
    <slot name="fab" v-bind:scrollTo="scrollTo" v-bind:showScroll="showScroll">
      <div class="quick-link-btn">
        <v-btn :href="'http://pf.kakao.com/_xcxlNNxj'" target="_blank">
          <span class="blind">카톡상담바로가기</span>
        </v-btn>
      </div>
      <!-- <v-btn v-show="showScroll" fixed bottom right color="primary" class="top-button mt-n10" @click="scrollTo(0,0)"  style="z-index:1000;">
                <div>
                    <v-icon class="mt-n2">{{ mdiChevronUp }}</v-icon>
                    <img src="/images/top-arrow.png" alt="">
                    <div class="body-2">위로</div>
                </div>
            </v-btn> -->
    </slot>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  mdiChevronUp,
  mdiAccountPlusOutline,
  mdiTextBoxOutline,
  mdiLockOutline,
  mdiCartOutline,
  mdiAccountOutline,
  mdiLogoutVariant,
  mdiAccountPlus,
} from "@mdi/js";
import GlobalNavigationBar from "@/components/client/gnb/global-navigation-bar.vue";
import CustomFooter from "@/components/client/inc/custom-footer.vue";

export default {
  components: {
    GlobalNavigationBar,
    CustomFooter,
  },
  data() {
    return {
      mdiChevronUp,
      mdiTextBoxOutline,
      mdiAccountPlusOutline,
      mdiAccountOutline,
      mdiLockOutline,
      mdiCartOutline,
      mdiLogoutVariant,
      mdiAccountPlus,

      maxWidth: 1200,

      drawer: false,

      isActive: false,

      showScroll: false,
      showFooter: false,

      searchValue:
        -1 < this.$route.path.indexOf("/search")
          ? this.$route.query.searchValue
          : null,
    };
  },
  mounted() {
    this.getSiteInfo();
    // this.$nextTick(_ => this.isActive = true )
  },
  methods: {
    ...mapActions("siteInfo", ["getSiteInfo"]),
    login() {
      this.$router.push(`/login`);
    },
    logout() {
      const go = confirm("로그아웃 하시겠습니까?");
      if (go) this.$store.dispatch("logout");
    },
    search() {
      if (!this.searchValue) {
        alert("검색어 키워드를 입력해주세요");
        return;
      }

      this.$router.push(`/search?searchValue=${this.searchValue}`);
    },

    open(url) {
      window.open(url);
    },

    onFooter(entries, observer) {
      this.showFooter = entries[0].isIntersecting;
    },

    onScroll(entries, observer) {
      this.showScroll = !entries[0].isIntersecting;
    },

    scrollTo(x, y) {
      return window.scrollTo(x, y);
    },
  },
  computed: {
    ...mapState(["siteInfo"]),
    accessToken() {
      return this.$store.state.accessToken;
    },
    logon() {
      return !!this.accessToken;
    },
    logoff() {
      return !this.accessToken;
    },
    backgroundColor() {
      return this.$route.path == "/"
        ? "white"
        : "var(--v-content-base) !important";
    },
  },
  watch: {
    accessToken() {
      if (!this.accessToken) {
        this.$router.go(0);
      }
    },
  },
};
</script>

<style scoped>
.v-cloak {
  display: none;
}

.header {
  box-shadow: 0px 2px 6px rgba(200, 200, 200, 0.1) !important;
  z-index: 10;
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 13px 0px rgba(74, 74, 74, 0.2) !important;
  -webkit-transition: all ease 0.15s;
  transition: all ease 0.15s;
}
.header >>> .v-toolbar__content {
  padding: 0 !important;
}

.prepend-menu {
  padding: 24px 36px;
}
.prepend-menu ul {
  list-style: none;
}
.prepend-menu > a {
  display: block;
  margin-top: 16px;
  color: black;
}
.prepend-menu > div {
  margin-top: 16px;
}
.prepend-menu > *:first-child {
  margin-top: 0px;
}
.prepend-menu .sub-menu li {
  margin-top: 8px;
}
.prepend-menu .sub-menu a {
  position: relative;
  color: black;
}
.prepend-menu .sub-menu a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: -2px;
  width: calc(100% + 4px);
  height: 10px;
  background: #2676f3;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition: all ease 0.15s;
  transition: all ease 0.15s;
  opacity: 0.25;
}
.prepend-menu .sub-menu a:hover::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.quick-menu .point {
  display: block;
  position: absolute;
  top: -60px;
  left: 8px;
  margin: 0 auto 7px;
  animation: point 2s ease infinite;
}
.quick-menu >>> .v-btn__content span {
  font-size: 14px !important;
  letter-spacing: -0.06em !important;
}

@keyframes point {
  0%,
  30%,
  60%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  15%,
  45% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.footer-serve a {
  font-size: 14px;
  color: #111111;
}

.footer-info {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin: -2px -10px;
  max-width: 500px;
  width: 100%;
}

.footer-info li {
  padding: 2px 10px;
  font-size: 12px;
  position: relative;
}
.footer-info li::before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 12px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #969696;
}
.footer-info li:first-child,
.footer-info li:nth-child(2) {
  width: 100%;
}

.footer-info li:first-child::before,
.footer-info li:nth-child(2)::before,
.footer-info li:nth-child(3)::before,
.footer-info li:nth-child(6)::before {
  display: none;
}

.top-button {
  min-width: 57px !important;
  height: 57px !important;
  padding: 10px 15px !important;
}

@media (min-width: 1024px) {
  .footer-serve a {
    font-size: 16px;
  }

  .footer-info li {
    font-size: 14px;
  }
}
</style>
